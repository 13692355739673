<template>
  <vx-card title="Reversal Payment">
    <vs-tabs>
      <vs-tab label="Open">
        <open></open>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open/index.vue";
import Reversed from "./reversed/index.vue";
export default {
  components: {
    Open,
    Reversed,
  },
};
</script>
