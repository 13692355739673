import api from '../../axios'

export const dataPayment = (params) => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/payment/data-table', {
            params: {
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.order,
                sort: params.sort,
                status: params.status ? params.status : 0,
                type: params.type ? params.type : 0,
            }
        }).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const dataCustomer = (query = "") => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/payment/customer?search='+query).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const dataPaymentAccount = (code) => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/payment/payment-account?code=' + code).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const postPayment = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/store', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const postPaymentLine = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/store/line', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const getPaymentLine = (request) => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/payment/line/data-table', {params : request}).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const postInvoiceDeposit = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/store/inv/deposit', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const updateInvoiceDeposit = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/update/inv/deposit', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const deleteInvoiceDeposit = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/delete/inv/deposit', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const dataInvoiceCustomer = (code, type = 1) => {
    return new Promise((resolve, reject) => {
        api.get(`/api/v1/payment/customer/invoice?code=${code}&type=${type}`).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const cancelPaymentLine = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment/store/line/cancel', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}
